
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import KpiWidget from '../common/KpiWidget.vue';
import { BendingToolChangesDataPerDevice } from '@/models/Charts/bendingToolChangesKpiWidgetGenerator';

/**
 * Actually 'Tool Changes' widget.
 */
@Component({
  components: {
    KpiWidget,
  },
})
export default class BendingToolChangesKpiWidget extends Vue {
  @Prop({ required: true })
  private data!: BendingToolChangesDataPerDevice[] | null;

  @Prop({ required: true })
  private generator!: ChartGenerator<BendingToolChangesDataPerDevice>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private mappingFunction(entry: BendingToolChangesDataPerDevice) {
    return entry.toolChanges;
  }
}
